<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 2322 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_494_132)">
      <path d="M2321.31 0.0634766H1175.17V1146.21H2321.31V0.0634766Z" fill="#D60A52" />
      <path
        d="M1360.98 804.366H1404.55V705.947H1467.69V668.012H1404.55V618.775H1471.85V580.84H1360.98V804.366Z"
        fill="white"
      />
      <path d="M1530.22 580.864H1488.73V804.366H1530.22V580.864Z" fill="white" />
      <path
        d="M1619.79 772.356C1598.44 772.356 1585.41 751.3 1585.41 731.749C1585.41 711.588 1598.46 690.83 1619.79 690.83C1641.12 690.83 1654.17 711.588 1654.17 731.749C1654.17 751.3 1641.12 772.356 1619.79 772.356ZM1619.79 654.077C1574.43 654.077 1543.31 686.395 1543.31 731.749C1543.31 776.8 1574.15 809.109 1619.79 809.109C1665.43 809.109 1696.26 776.8 1696.26 731.749C1696.26 686.395 1665.14 654.077 1619.79 654.077Z"
        fill="white"
      />
      <path
        d="M1887.81 658.819L1856.97 743.916L1821.12 654.097H1801.26L1765.69 742.729L1735.46 658.839H1690.09L1757.09 807.355H1778.45L1813.12 718.723L1844.25 807.355H1865.29L1932.89 658.839L1887.81 658.819Z"
        fill="white"
      />
      <path
        d="M1404.56 377.329H1410.48C1433.61 377.329 1451.1 382.672 1451.1 410.238C1451.1 437.511 1434.21 444.627 1410.19 444.627H1404.56V377.329ZM1404.56 475.157H1405.14L1459.4 565.279H1512.15L1449.03 471.016C1478.96 465.985 1494.68 437.521 1494.68 409.061C1494.68 361.333 1464.14 341.772 1419.97 341.772H1360.98V565.279H1404.56V475.157Z"
        fill="white"
      />
      <path
        d="M1536.76 383.259C1541.62 383.259 1546.38 381.816 1550.43 379.113C1554.47 376.41 1557.63 372.568 1559.49 368.072C1561.35 363.577 1561.84 358.631 1560.89 353.858C1559.94 349.086 1557.6 344.703 1554.15 341.262C1550.71 337.822 1546.33 335.479 1541.56 334.529C1536.79 333.58 1531.84 334.067 1527.34 335.929C1522.85 337.791 1519.01 340.944 1516.3 344.99C1513.6 349.036 1512.16 353.792 1512.16 358.658C1512.15 361.891 1512.78 365.093 1514.02 368.081C1515.25 371.069 1517.06 373.784 1519.35 376.07C1521.63 378.356 1524.35 380.167 1527.34 381.401C1530.32 382.635 1533.53 383.266 1536.76 383.259Z"
        fill="white"
      />
      <path d="M1557.5 419.729H1516.01V565.275H1557.5V419.729Z" fill="white" />
      <path
        d="M1642.89 451.729C1664.24 451.729 1677.26 473.082 1677.26 492.638C1677.26 512.194 1664.23 533.26 1642.89 533.26C1621.54 533.26 1608.49 512.199 1608.49 492.638C1608.49 473.077 1621.54 451.729 1642.89 451.729ZM1631.91 570.007C1639.56 570.007 1647.11 568.272 1653.99 564.934C1660.87 561.596 1666.9 556.741 1671.64 550.735H1672.24V560.225C1672.24 582.154 1671.64 607.947 1642.29 607.947C1635.14 608.223 1628.15 605.726 1622.8 600.976C1617.44 596.227 1614.12 589.592 1613.54 582.457H1569.66C1571.74 621.291 1607.61 642.937 1643.19 642.937C1697.73 642.937 1713.73 603.801 1713.73 556.376V419.723H1672.24V433.628H1671.64C1667.18 427.872 1661.48 423.201 1654.96 419.967C1648.44 416.732 1641.27 415.017 1633.99 414.951C1590.71 414.951 1566.4 453.487 1566.4 493.512C1566.4 532.634 1589.24 569.988 1631.92 569.988"
        fill="white"
      />
      <path d="M1146.6 0.0634766H0.454102V1146.21H1146.6V0.0634766Z" fill="#D60A52" />
      <path
        d="M768.46 406.23H909.909V341.563H768.46C632.468 341.563 612.167 424.102 612.167 473.3C612.167 516.578 626.96 550.57 655.301 573.115C626.96 595.685 612.167 629.677 612.167 672.93V804.696H683.676V672.96C683.676 639.792 693.752 605.885 768.45 605.885H909.899V540.375H768.46C693.762 540.375 683.686 506.482 683.686 473.3C683.686 440.117 693.762 406.26 768.46 406.26"
        fill="white"
      />
      <path
        d="M430.007 536.114L353.785 406.23H391.094C465.796 406.23 475.872 440.123 475.872 473.27C475.872 508.762 462.012 528.059 430.007 536.074M547.386 473.27C547.386 424.058 527.091 341.534 391.094 341.534H237.125L353.06 540.33H249.649V804.697H321.158V605.036H390.682L505.891 804.697H586.145L464.083 594.409C518.013 576.035 547.371 533.741 547.371 473.31"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_494_132">
        <rect width="2320.86" height="1146.14" fill="white" transform="translate(0.454102 0.0644531)" />
      </clipPath>
    </defs>
  </svg>
</template>
